.Contact .left {
  width: 36%;
}
.Contact .right {
  width: 50%;
  height: 50%;
}
.Contact .box {
  padding: 30px;
}
.Contact .details h1 {
  margin: 20px 0 20px 0;
  font-size: 35px;
}
.Contact .details p {
  font-size: 100%;
}
.Contact .button {
  margin-top: 20px;
}
.Contact button {
  margin-right: 20px;
}

.Contact .right {
  padding: 25px;
}
.Contact .input span {
  font-size: 13px;
}
.Contact .right button {
  width: 100%;

  /* height: 50px; */
  color: #ff0101;
}
.Contact .right button:hover {
  color: white;
}
.Contact .left .box .img{
  width: 3%;
}
.Contact .right button i {
  margin-left: 10px;
}
@media (max-width: 768px) {
  .Contact .d_flex {
    flex-direction: column;
  }
  .Contact .left,.Contact .right {
    width: 90%;
    margin-top: 50px;
    margin-left: auto;
  }
}
