.Resume .heading h1 {
  font-size: 50px;
}
.Resume .content {
  max-width: 85%;
  margin: auto;
}
.Resume .box {
  padding: 40px;
}
.Resume hr {
  margin: 30px 0 30px 0;
  border: 1px solid #afb7bc;
  background-color: #006eff;
}
.Resume .rate .btn_shadow {
  color: #ff0101;
  padding: 15px 30px;
  font-weight: bold;
  transition: 0.5s;
}
.Resume .box {
  margin-bottom: 30px;
  /* position: relative; */
}
.Resume .btn_shadow:hover {
  transform: translate(0);
}
.Resume .box:hover .btn_shadow {
  background: #ff3a75;
  box-shadow: none;
}
.Center{
  display: flex;
  justify-content: center;
}
.h2{
  align-items: center;
}
.Resume .right {
  position: relative;
  margin: 10px;
}
.Resume .left {
  position: relative;
}
.Resume .box::after {
  content: "";
  position: absolute;
  top: 13%;
  left: -8%;
  height: 5px;
  width: 50px;
  background: #d9dee2;
}
.Resume .box::before {
  content: "";
  position: absolute;
  top: 11%;
  left: -10%;
  height: 10px;
  width: 10px;
  background: #fff;
  border: 5px solid #d9dee2;
  border-radius: 50%;
  z-index: 5;
  transition: 0.5s;
}
.Resume .box:hover.box:before {
  background: #ff3a75;
}
.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}
#file{
  width: 100%;
  height: 30px;
  margin-bottom: 7px;

}
.span{
 display: flex;
 justify-content: flex-end;
 justify-content: space-between;
 margin-bottom: -2%;
 
}
.label{
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;


}
@media (max-width: 768px) {
  .Resume .d_flex {
    flex-direction: column;
  }
}
