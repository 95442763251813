@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  padding: 0;
  margin: 0;
  /* box-sizing: border-box; */

}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #f0f7f7;
}

.container {
   /* height: 100%; */
  max-width: 95%;
  margin: auto;
  /* margin-top: -20px; */
}
.d_flex {
   display: flex;
  justify-content: space-between;
}
.f_flex {

  display: flex;
}
a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}
li {
  font-size: 20px;
  list-style: none;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

button {
  border: none;
  outline: none;
  background-color: none;
}

.btn_shadow {
  padding: 1rem 1.5rem;
  border-radius: 6px;
  margin-top:1rem;
  transition: 0.3s all ease;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  cursor: pointer;
  color: rgb(0, 0, 0);
  transition: 0.5s;
}
.btn_shadow {
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  box-shadow: 3px 3px 5px #708085, -4px -4px 8px #3296c4;
  transition: 0.5s;
}
.btn_shadow:hover {
  background: #239dc2;
  color: rgb(0, 0, 0);
  transform: translateY(-10px);
  transition: 0.5s;
}
.box_shodow {
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  box-shadow: 4px 4px 8px #cbcbcb, -4px -4px 8px #484646;
  border-radius: 10px;
  transition: 0.5s;
}
.row,
.left,
.right {
  width: 50%;
}
.right{
  margin-left: 45px;
}
.top {
  margin-top: 30px;
  margin-left: 20px;
}
.mtop {
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.heading {
  margin: 50px 0 50px 0;
}

.heading h1 {
  font-size: 45px;
  color: #000000;
  text-align: center;
}
h1 {
  color: #3c3e41;
  transition: 0.5s;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 35px;
}
p {
  color: #313336;
  transition: 0.5s;
}
.text-center {
  text-align: center;
}
.primary_color {
  color: #ff014f;
}
textarea,
input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  border: 3px solid #e2e8ec;
  margin-bottom: 10px;
}
/*----------Footer---------*/
footer {
 background-color: #000000;
 height: 55px;
 
}
footer p a{
  text-decoration: none;
  color: rgb(255, 0, 0);
}
footer p {
  color: #ffffff;
  margin-top: 30px;
  text-align: center;
  padding: 10px;
}
/*----------Footer---------*/
@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .left,
  .right {
    width: 100%;

  }
}
